<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-19 10:28:24
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-20 14:40:27
-->
<template>
  <div class="container-warp">
    <div v-for="(stageItem,stageIndex) in courseArrangeData" :key="stageIndex">
      <com-title style="margin-bottom:16px;">{{stageItem.stage}}</com-title>
      <div class="study-item" v-for="(item,index) in stageItem.studyPlanCourseList" :key="index">
        <div class="study-item-left">
          <div class="img-box">
            <img :src="item.cover" alt="" />
          </div>
          <!-- 课程--开始 -->
          <div class="info-box" v-if="item.typeValue!=='考试'">
            <div class="info-box-t">
              {{item.name||'—'}}
              <span>{{item.typeValue||'—'}}</span>
            </div>
            <div class="info-box-m">
              {{item.description||'—'}}
            </div>
            <div class="info-box-b" v-if="item.typeValue=='线上课程'">
              <div style="color:#409EFF;margin-right:4px;">已学{{item.studySchedule||0}}%</div>
              <el-progress :percentage="Number(item.studySchedule)||0" :show-text='false'></el-progress>
            </div>
            <div class="info-box-m" style="display:flex;" v-else>
              <div style="margin-right:4px;">带教人</div>
              <div>{{item.instructorName||'—'}}</div>
            </div>
          </div>
          <!-- 课程--结束 -->
          <!-- 考试--开始 -->
          <div class="info-box" v-else>
            <div class="info-box-t">
              {{item.testPaperName||'—'}}
              <span>{{item.typeValue||'—'}}</span>
            </div>
            <div class="info-box-m">
              考试时间<span>{{item.startTime}} - {{item.endTime}}</span>
            </div>
            <div class="info-box-b">
              <span :class="item.examStatusValue=='缺考'?'redBg basebg':(item.examStatusValue=='待考'?'greyBg basebg':(item.examStatusValue=='已考'?'blueBg basebg':''))">{{item.examStatusValue}}</span>
              考试成绩<span style="color: #0089ff;margin-left:2PX;">{{item.mark||'0'}}</span>分
            </div>
          </div>
          <!-- 考试--结束 -->
        </div>
        <div class="study-item-right" v-if="item.typeValue!=='考试'">
          <el-button  type="text" @click="_continueStudy(item)" v-if="item.statusValue!='未开始'">继续学习</el-button>
          <el-button  type="text" @click="_startStudy(item)" v-else>开始学习</el-button>
        </div>
        <div class="study-item-right" v-else-if="item.examStatusValue!=='缺考'">
          <el-button  type="text" @click="_examResult(item)" v-if="item.examStatusValue=='已考'">考试结果</el-button>
          <!-- 考试详情--未完成状态下，还有不允许查看状态展示考试结束详情页面 -->
          <el-button  type="text" v-if="item.examStatusValue=='待考'" @click="_startExamBtn(item)">考试详情</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
export default {
  components: { comTitle },
  props: {
    courseArrangeData: {
      type: Array,
      default: () => []
    },
    studyPlanFlag: {
      type: Number,
      default: null
    }
  },
  methods: {
    // 开始学习
    _startStudy (row) {
      console.log(row, 'jhgjgj111111')
      if (row.typeValue === '线下课程') {
        this.$router.push({
          path: '/myOfflineCourseDetails',
          query: {
            courseId: row.id,
            studyPlanId: this.$route.query.studyPlanId
          }
        })
      }
      if (row.typeValue === '线上课程') {
        this.$router.push({
          path: '/myOnlineCourseDetails',
          query: {
            courseId: row.id,
            studyPlanId: this.$route.query.studyPlanId
          }
        })
      }
    },
    // 继续学习
    _continueStudy (row) {
      console.log(row, 'jhgjgj222')
      if (row.typeValue === '线下课程') {
        this.$router.push({
          path: '/myOfflineCourseDetails',
          query: {
            courseId: row.id,
            studyPlanId: this.$route.query.studyPlanId
          }
        })
      }
      if (row.typeValue === '线上课程') {
        this.$router.push({
          path: '/myOnlineCourseDetails',
          query: {
            courseId: row.id,
            studyPlanId: this.$route.query.studyPlanId
          }
        })
      }
    },
    // 考试结果
    _examResult (row) {
      // studyEndFlag学习计划结束标志，如果是true说明学习计划结束，不允许查看考试详情---考试结束页面
      if (this.studyPlanFlag !== 1) {
        this.$router.push({
          path: '/endOfExamination',
          query: {
            examInfoId: row.relevanceId,
            employeeId: this.$store.state.userList.userId,
            studyPlanId: this.$route.query.studyPlanId
          }
        })
      } else {
        // 学习计划未设置或者未开始
        if (row.correctStatusValue === '待批改') {
          this.$router.push({
            path: '/endOfExamination',
            query: {
              examInfoId: row.relevanceId,
              employeeId: this.$store.state.userList.userId,
              studyPlanId: this.$route.query.studyPlanId
            }
          })
        } else {
          this.$router.push({
            path: '/studentExamResultDetail',
            query: {
              examInfoId: row.relevanceId,
              employeeId: this.$store.state.userList.userId,
              studyPlanId: this.$route.query.studyPlanId,
              typeValue: 'courseArrange'
            }
          })
        }
      }
    },
    // 考试详情
    _startExamBtn (item) {
      this.$router.push({
        path: '/examinationStartDetail',
        query: {
          id: item.relevanceId, // 考试id
          studyPlanId: this.$route.query.studyPlanId,
          typeValue: 'courseArrange'
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.container-warp{
  height: 55vh;
  overflow-y: scroll;
}
  .study-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 16px;
  background: linear-gradient(to bottom, #f0f8ff, #ffffff);
  border: 1px solid #e7f4ff;
  border-radius: 8px;
  .study-item-left {
    display: flex;
    .img-box {
      margin-right: 12px;
      width: 120px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .info-box {
      .info-box-t {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          padding: 3px 12px;
          margin-left: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 8px 0px 8px 0px;
          background: linear-gradient(to right, #31adf6, #5096ff);
        }
      }
      .info-box-m {
        margin: 8px 0 16px;
        font-size: 14px;
        color: #666666;
      }
      .info-box-b {
        display: flex;
        align-items: center;
        font-size: 14px;
        /deep/.el-progress{
          width: 265px;
        }
        .basebg{
          display: inline-block;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 12px;
          margin-right: 6PX;
        }
        .redBg{
          background: #FFF2F1;
          color: #F04134;
          border: 1px solid #F04134;
        }
        .greyBg{
          background: #F5F8FF;
          color: #75849B;
          border: 1px solid #75849B;
        }
        .blueBg{
          background: #F2F8FF;
          color: #0089FF;
          border: 1px solid #0089FF;
        }
      }
    }
  }
}
</style>
