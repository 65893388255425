<template>
  <div class="container-warp">
    <div class="top-box">
      <div class="return-title">
          <div class="back-btn" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
          <span class="division">|</span>
          <div class="back-title">学习计划详情</div>
        </div>
      <div class="top-box-info">
        <div class="top-box-info-left">
          <img :src="studentLearnPlanInfo.cover" alt="" />
          <div class="top-box-info-left-xx">
            <div class="top-box-info-left-bt">
              {{studentLearnPlanInfo.planName}}<span>{{studentLearnPlanInfo.typeName}}</span>
            </div>
            <div class="top-box-info-left-kc">
              {{studentLearnPlanInfo.description||'—'}}
            </div>
            <div class="top-box-info-left-mc">
              <div class="info-box-b-l">
                <img src="@/assets/img/icon_Duration.png" alt="" />总时长
                <span style="color: #0089ff">{{_convertTimeToHMS(Number(studentLearnPlanInfo.totalTime||0)*1000)}}</span>
              </div>
              <div class="line-box"></div>
              <div class="info-box-b-m">
                <img src="@/assets/img/icon_Course.png" alt="" />
                <span style="color: #0089ff">{{studentLearnPlanInfo.courseSum||0}}</span>门课程
              </div>
              <div class="line-box"></div>
              <div class="info-box-b-r">
                <img src="@/assets/img/icon_Examination.png" alt="" />考试<span style="color: #0089ff">{{studentLearnPlanInfo.testedQuantity||0}}/{{studentLearnPlanInfo.totalQuantity||0}}</span>(通过{{studentLearnPlanInfo.passedQty||0}}场)
              </div>
              <div class="line-box"></div>
              <div class="info-box-b-r">
                <img src="@/assets/img/icon_Finaldate.png" alt="" />结束时间 {{studentLearnPlanInfo.endTime||"—"}}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="top-box-info-right">
          <el-button type="primary" plain  :style="{'--lock-btn': $store.state.settings.theme}">继续学习</el-button>
        </div> -->
      </div>
    </div>
    <div class="bottom-box">
      <el-tabs class="bottom-tab-box" v-model="activeName">
        <el-tab-pane label="介绍" name="1">
          <introduce :introduceData="introduceData"></introduce>
        </el-tab-pane>
        <el-tab-pane label="课程安排" name="2">
          <course-arrangement :courseArrangeData="courseArrangeData" :studyPlanFlag="Number(studentLearnPlanInfo.status)"></course-arrangement>
        </el-tab-pane>
        <el-tab-pane label="考试" name="3">
          <exam :examList="examList" :studyPlanFlag="Number(studentLearnPlanInfo.status)"></exam>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// import backTitle from '@/components/backTitle/index.vue'
import introduce from '../components/introduce.vue'
import courseArrangement from '../components/courseArrangement.vue'
import exam from '../components/exam.vue'
import { getStudentLearnArrangeDetailApi, getExamInfoByStudyIdApi, getArrangeCourseByStudyIdApi } from '@/api/study.js'
export default {
  components: {
    // backTitle,
    introduce,
    courseArrangement,
    exam
  },
  data () {
    return {
      activeName: '',
      studentLearnPlanDetail: {}, // 学习计划详情
      studentLearnPlanInfo: {
        studyPlanCode: '',
        planName: '',
        startTime: '',
        endTime: '',
        type: 0,
        typeName: '',
        cover: '',
        description: '',
        introduce: '',
        status: 0,
        courseSum: 0,
        totalExamNum: '',
        examedNum: '', //
        passExamNum: ''
      }, // 学习计划详情
      introduceData: '', // 学习计划介绍
      courseArrangeData: [], // 课程安排列表
      examList: [] // 考试安排列表
    }
  },
  watch: {
    activeName (newValue, oldValue) {
      this._getLearnArrangeDetail(newValue)
      console.log(newValue, oldValue)
    }
  },
  mounted () {

  },
  created () {
    this.activeName = '1'
    if (this.$route.query.studyPlanId) {
      this._getLearnArrangeDetail()
    }
  },
  methods: {
    // 获取详情
    _getLearnArrangeDetail (activeName) {
      getStudentLearnArrangeDetailApi({ id: this.$route.query.studyPlanId }).then((res) => {
        if (res.code === 200) {
          this.studentLearnPlanInfo = JSON.parse(JSON.stringify(res.data))
          this.introduceData = this.studentLearnPlanInfo.introduce
        }
      })
      if (activeName === '2') {
        getArrangeCourseByStudyIdApi({ studyPlanId: this.$route.query.studyPlanId }).then((res) => {
          if (res.code === 200) {
            this.courseArrangeData = res.data
          }
        })
      }
      if (activeName === '3') {
        getExamInfoByStudyIdApi({ studyPlanId: this.$route.query.studyPlanId }).then((res) => {
          if (res.code === 200) {
            console.log(res, 'jkhgh22')
            this.examList = res.data
          }
        })
      }
    },
    // 将毫秒转成时分秒
    _convertTimeToHMS  (tempTime) {
      let formatTime = ''
      const hours = parseInt((tempTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = parseInt((tempTime % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = parseInt((tempTime % (1000 * 60)) / 1000)
      const tempHours = hours > 9 ? hours : '0' + hours
      const tempMin = minutes > 9 ? minutes : '0' + minutes
      const tempSec = seconds > 9 ? seconds : '0' + seconds
      if (tempHours != 0) {
        formatTime = (`${tempHours}时${tempMin}分${tempSec}秒`).slice(0, 9)
      } else if (tempMin != 0) {
        formatTime = (`${tempMin}分${tempSec}秒`).slice(0, 6)
      } else {
        formatTime = (`${tempSec}秒`).slice(0, 3)
      }
      return formatTime
    },
    _backBtn () {
      this.$router.push({
        path: '/learningArrangement'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  padding: 16px 24px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  .top-box-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-box-info-left {
      display: flex;
      img {
        margin-right: 16px;
        width: 120px;
        height: 90px;
        border-radius: 4px;
      }
      .top-box-info-left-xx {
        .top-box-info-left-bt {
          margin: 4px 0;
          font-size: 16px;
          color: #333333;
          font-weight: 500;
          span {
            display: inline-block;
            padding: 3px 12px;
            margin-left: 16px;
            font-size: 12px;
            color: #ffffff;
            border-radius: 8px 0px 8px 0px;
            background: linear-gradient(to right, #31adf6, #5096ff);
          }
        }
        .top-box-info-left-kc {
          margin-bottom: 14px;
          font-size: 14px;
          color: #666666;
        }
        .top-box-info-left-mc {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #666666;
          .info-box-b-l,
          .info-box-b-m,
          .info-box-b-r {
            img {
              margin-right: 4px;
              width: 20px;
              height: 20px;
              vertical-align: middle;
            }
          }
          .line-box {
            margin: 0 12px;
            width: 1px;
            height: 16px;
            background: #dcdcdc;
          }
        }
      }
    }
  }
}
// 头部返回标题样式
.return-title{
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  .back-btn{
    font-size: 14px;
    color: #303133;
  }
  .division{
    color: #F3F3F3;
    width: 1px;
    background-color: #F3F3F3;
    border-radius: 2px;
    margin: 0px 16px;
  }
  .back-title{
    font-size: 16px;
    color: #333333;
  }
}
/deep/.el-button {
  margin-left: 16px !important;
  font-size: 14px;
  height: 32px;
}
.bottom-box {
  margin-top: 16px;
  padding: 0 24px;
  background-color: #ffffff;
  border-radius: 4px;
  height: 65vh;
  .bottom-tab-box {
    .el-tabs__content{
      height: 10vh;
      overflow-y: scroll;
    }
    /deep/.el-tabs__nav {
      height: 55px;
    }
    /deep/.el-tabs__item {
      line-height: 55px;
    }
  }
}
</style>
